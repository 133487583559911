export const skills = [
  "Java",
  "Spring Boot",
  "JPA/Hibernate",
  "C#",
  ".NET MVC",
  "MSSQL",
  "MySQL",
  "PLSQL",
  "React",
  "Bootstrap",
];
