import React from "react";

export default function Footer() {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <footer className="text-gray-600 body-font">
            <div className="bg-gray-800">
                <div
                    className="
        container
        mx-auto
        py-4
        px-5
        flex flex-wrap flex-col
        sm:flex-row
      "
                >
                    <p className="text-gray-500 text-sm text-center sm:text-left">
                        © {year} - Anıl Hanoğlu
                    </p>

                </div>
            </div>
        </footer>
    );
}