import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { School, Work, Star } from '@mui/icons-material';

export default function WorkTimeline() {
    return (
        <div id="background">
            <div className="text-center mb-20">
                <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
                    My Background
                </h1>
            </div>

            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                    date="2013 - 2019"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<School />}
                >
                    <h3 className="vertical-timeline-element-title">Ege University - Bachelor Science of Computer Engineering</h3>
                    <h4 className="vertical-timeline-element-subtitle">Izmir, TURKEY</h4>
                    <p>
                        GPA : 3.1/4
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                    date="2016 - 2017"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<School />}
                >
                    <h3 className="vertical-timeline-element-title">Technische Hochschule Mittelhessen - BASc Informatik</h3>
                    <h4 className="vertical-timeline-element-subtitle">Giessen, GERMANY</h4>
                    <p>
                        Erasmus+ Exchange Programme
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgba(2,131,90,1)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgba(2,131,90,1)' }}
                    date="July/2019 – Aug/2020"
                    iconStyle={{ background: 'rgba(2,131,90,1)', color: '#fff' }}
                    icon={<Work />}
                >
                    <h3 className="vertical-timeline-element-title">Software Engineer</h3>
                    <h4 className="vertical-timeline-element-subtitle">Softtech A.Ş.</h4>
                    <p>
                        Development and maintanence of applications of Internet Banking Web Application of Turkiye Is Bankasi A.Ş.

                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgba(2,131,90,1)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgba(2,131,90,1)' }}
                    date="Aug/2020 – Jan/2021"
                    iconStyle={{ background: 'rgba(2,131,90,1)', color: '#fff' }}
                    icon={<Work />}
                >
                    <h3 className="vertical-timeline-element-title">Software Engineer</h3>
                    <h4 className="vertical-timeline-element-subtitle">Intertech Inc.</h4>
                    <p>
                        Development and maintanence of retail loan management application of the banking software package-product called Intervision.

                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgba(2,131,90,1)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgba(2,131,90,1)' }}
                    date="Jan/2021 – Sep/2021"
                    iconStyle={{ background: 'rgba(2,131,90,1)', color: '#fff' }}
                    icon={<Work />}
                >
                    <h3 className="vertical-timeline-element-title">Software Engineer</h3>
                    <h4 className="vertical-timeline-element-subtitle">Yapi Kredi Technology</h4>
                    <p>
                        I was a member of Bankassurance technologies development team which were responsible for
                        maintanence and development of insurance applications of Yapi Kredi Bank.

                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgba(2,131,90,1)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgba(2,131,90,1)' }}
                    date="Nov/2021 – Oct/2022"
                    iconStyle={{ background: 'rgba(2,131,90,1)', color: '#fff' }}
                    icon={<Work />}
                >
                    <h3 className="vertical-timeline-element-title">Software Engineer</h3>
                    <h4 className="vertical-timeline-element-subtitle">Turk Ekonomi Bankasi</h4>
                    <p>
                        Software engineer at Banking Applications and Architectural Softwares team.
                        Ownership of various internal applications that provide pricing, product management,
                        identitiy management, authorization – authentication and gateway applications.

                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgba(2,131,90,1)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgba(2,131,90,1)' }}
                    date="Oct/2022 – ..."
                    iconStyle={{ background: 'rgba(2,131,90,1)', color: '#fff' }}
                    icon={<Work />}
                >
                    <h3 className="vertical-timeline-element-title">Software Engineer</h3>
                    <h4 className="vertical-timeline-element-subtitle">Akbank T.A.Ş.</h4>
                    <p>
                        Membership of Akbank Mobile application developer team. I am mainly responsible for transfer-payment subdomain of mobile application platform.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                    icon={<Star />}
                />
            </VerticalTimeline>
        </div>
    );
}