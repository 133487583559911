import React from "react";
import About from "./components/About";
import Navbar from "./components/Navbar";
import Skills from "./components/Skills";
import WorkTimeline from "./components/WorkTimeline";
import Footer from "./components/Footer";

export default function App() {
  return (
    <main className="text-gray-400 bg-gray-900 body-font">
      <Navbar />
      <About />
      <WorkTimeline />
      <Skills />
      <Footer />
    </main>
  );
}