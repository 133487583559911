
import { Typography } from "@mui/material";
import { MailOutline, LinkedIn } from '@mui/icons-material';
import { animateScroll as scroll, Link } from 'react-scroll';
import React from "react";

const scrollTop = () => { scroll.scrollToTop(); }

export default function Navbar() {
    return (
        <header className="bg-gray-800 md:sticky top-0 z-10">
            <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <a className="title-font font-medium text-white mb-4 md:mb-0">
                    <span style={{ cursor: "pointer" }} className="ml-3 text-xl" onClick={scrollTop}>
                        Anıl Hanoğlu
                    </span>
                </a>
                <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l 
                md:border-gray-700 flex flex-wrap items-center text-base justify-center">
                    <span style={{ cursor: "pointer" }} className="mr-5 hover:text-white">
                        <Link to="background" smooth={true}>
                            Background
                        </Link>
                    </span>
                    <span style={{ cursor: "pointer" }} className="mr-5 hover:text-white">
                        <Link to="skills" smooth={true}>
                            Skills
                        </Link>
                    </span>
                </nav>

                <Typography variant="h1" style={{ fontSize: '1rem' }} marginRight="15px"
                    sx={{ fontWeight: 500 }}>
                    Contact Me
                </Typography>

                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 m-1 border border-blue-700 rounded">
                    <a href="https://www.linkedin.com/in/anilhanoglu/">
                        <LinkedIn />
                    </a>
                </button>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 m-1 border border-blue-700 rounded">
                    <a href="mailto:anilhanoglu@gmail.com">
                        <MailOutline />
                    </a>
                </button>
            </div>
        </header>
    );
}